import { template as template_611fbd904eaa4be7a0a9b8b1f9493885 } from "@ember/template-compiler";
const FKLabel = template_611fbd904eaa4be7a0a9b8b1f9493885(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
