import { template as template_6f285f9ac0f744a5a6094384c8a59f39 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6f285f9ac0f744a5a6094384c8a59f39(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
